<template>
    <div class="container">
        <Subtabla tabla='contactos' tabla_preferencias='contactos' :filtros="[{'campo':'EMPRESA','valor':id}]" :eliminable="true" />
    </div>
</template>

<script>
import Subtabla from '@/components/Subtabla.vue'
export default {
    name: 'EmpresaContactos',
    props:{
        id: String
    },
    components: {
        Subtabla
    }
}
</script>

<style scoped>
.container{
    overflow: scroll;
    border: 1px solid black;
}
</style>