<template>
  <div class="root" v-if="datos">
      <button @click="eliminar">Eliminar</button>
      <EmpresaGeneral :datos="datos" />
      <button @click="nuevocontacto">Nuevo Contacto</button>
      <EmpresaContactos ref="contactos" :id="datos.ID" class="da" />
      <EmpresaPresupuestos :id="datos.ID" class="da" />
  </div>
</template>

<script>
import EmpresaGeneral from '@/components/EmpresaGeneral.vue'
import EmpresaPresupuestos from '@/components/EmpresaPresupuestos.vue'
import EmpresaContactos from '@/components/EmpresaContactos.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'DetalleEmpresa',
  data: function(){
    return {
      datos: null,
      tabla: 'empresas',
      id: this.$route.params.id
    }
  },
  props:{
    id: {
      type: String,
      required: true
    }
  },
  components:{
    EmpresaGeneral,
    EmpresaPresupuestos,
    EmpresaContactos
  },
  computed:{
    ...mapState(['tablas'])
  },
  methods:{
    ...mapActions(['api','cargartabla']),
    async eliminar(){
      const res = await this.api([{'campo':'tabla','valor':'empresas'},{'campo':'ID','valor':this.id},'eliminar'])
      alert(res)
      if(res==='Empresa eliminada correctamente'){
          this.$router.push('/empresas')
      }
    },
    async nuevocontacto(){
      const res = await this.api([{'campo':'tabla','valor':'contactos'},{'campo':'EMPRESA','valor':this.id},'nuevoregistro'])
      this.$router.go()
    }
  },
  async beforeMount(){
    if(!this.tablas[this.tabla]){
        await this.cargartabla([this.tabla,this.tabla])
    }
    const id = this.id
    this.datos = this.tablas[this.tabla].filter(
        function(e) {
            if(e['ID']){
                return e['ID']===id
            }
        }
    )[0]
    if(!this.datos){
        this.$router.push('/'+this.tabla)
    }
  }
}
</script>

<style scoped>
.root{
  padding: 0 2%;
}
.da{
  margin: 0;
  width: 100%;
  height: 60vh;
}
</style>