<template>
  <table v-if="display" :key="key">
      <tr class="titulos">
          <th v-for="(item) in preferencias[tabla_preferencias]" :key="item" >
            <div>
              <a>
                {{listas.campos[tabla][item].titulo}}
              </a>
            </div>
          </th>
      </tr>
      <tr class="registro" v-for="(a,registro) in display" :key="registro">
        <td v-for="(item,index) in preferencias[tabla_preferencias]" :key="index">
          <a v-if="listas.campos[tabla][item].nombre==='ID'" @click="link(a[listas.campos[tabla][item].nombre])">{{a[listas.campos[tabla][item].nombre]}}</a>
          <span v-else-if="listas.campos[tabla][item].tipo==='option' || listas.campos[tabla][item].tipo==='option-long'">{{a['Z'+listas.campos[tabla][item].nombre]}}</span>
          <span v-else>{{a[listas.campos[tabla][item].nombre]}}</span>
        </td>
        <td v-if="eliminar"><button @click="eliminar(a.ID);key++">x</button></td>
      </tr>
    </table>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    name: 'Subtabla',
    data: function (){
      return {
        display: null,
        registros: 25000,
        key: 1
      }
    },
    props: {
        tabla: String,
        tabla_preferencias: String,
        filtros: Array,
        eliminable: Boolean
    },
    computed: {
        ...mapState(['cache','tablas','listas','preferencias']),
    },
    methods: {
        ...mapActions(['api']),
        link(id){
          for (let index = 0; index < this.tablas[this.tabla].length; index++) {
            if(this.tablas[this.tabla][index]['ID']===id){
              this.cache.actuales[this.tabla] = index
              break
            }            
          }
          this.$router.push('/detalle'+this.tabla+'/'+id)
        },
        async eliminar(id){
          const datos = new FormData
          datos.append("tabla",this.tabla)
          datos.append("ID",id)
          const data = await fetch('https://www.centrocatalan.synology.me/php2/eliminar.php',{
            method: 'POST',
            body: datos
          })
          const res = await data.json()
          this.cache.cargas[this.tabla] = null
          this.$router.go()
        }
    },
    async beforeMount(){
      if(this.tabla && this.tabla_preferencias){
        let res = await this.api([{'campo':'TABLA','valor':this.tabla_preferencias.toUpperCase()},{'campo':'USUARIO','valor':this.cache.user},{'campo':'tipo','valor':'pedir'},'preferencias'])
        this.preferencias[this.tabla_preferencias] = JSON.parse(res.orden[0]['DATOS'])
        this.listas.campos[this.tabla_preferencias] = Object.keys(res.campos).map(function(x){
          return JSON.parse(res.campos[x]['DATOS'])
        }) 
        if(!this.tablas[this.tabla] || (this.cache.cargas[this.tabla] && (Date.now()-this.cache.cargas[this.tabla])>300000)){
          this.tablas[this.tabla] = await this.api([{'campo':'tabla','valor':this.tabla},{'campo':'limit','valor':this.registros},'tablas'])
          this.cache.cargas[this.tabla] = Date.now()
        }
        let original = this.tablas[this.tabla]
        for (let index = 0; index < this.filtros.length; index++) {
          let campo = this.filtros[index].campo
          let valor = this.filtros[index].valor
          if (typeof campo === 'object') {
            original = original.filter(function(e){
              return e[campo[0]] === valor || e[campo[1]] === valor
            })
          }else{
            original = original.filter(function(e){
              return e[campo] === valor
            })
          }
        }
        this.display = original
        this.key++
      }
    }
}
</script>

<style scoped>
.container{
  height: 93vh;
  overflow-y: scroll;
}
th,.filtros td {
  position: sticky;
  background-color: rgb(255, 172, 116);
  color: white;
}
th{
  top: 0;
  height: 5vh;
}
th a{
  width: max-content;
}
th div{
  display: flex;
}
.filtros td{
  top: 5.3vh;
}
.titulos:hover{
  background: white;
}
table{
  text-align: left;
  margin: 0;
  border-collapse: collapse;
}
.registro{
  height: 10vh;
  text-align: left;
}
.registro td{
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  border-bottom: 1px black solid;
}
th{
  text-align: left;
}
tr:hover{
  background-color: rgb(240, 240, 240);
}
td a{
  cursor: pointer;
}
.registro a:hover{
  text-decoration: underline;
}
</style>