<template>
    <div>
        <Subtabla tabla='presupuestos' tabla_preferencias='presupuestos' :filtros="[{'campo':['CLIENTE','ARQUITECTO'],'valor':id}]"/>
    </div>
</template>

<script>
import Subtabla from '@/components/Subtabla.vue'
import {mapState} from 'vuex'
export default {
    name:'EmpresaPresupuestos',
    props:{
        id: String
    },
    components:{
        Subtabla
    },
    computed:{
        ...mapState(['detalles','listas','cache','tablas'])
    }
}
</script>

<style scoped>
div{
    border: 1px solid black;
    overflow: scroll;
}
</style>