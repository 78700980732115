<template>
    <form @change="changes">
        <div>
            <label for="">Empresa</label>
            <input type="text" name="EMPRESA" v-model="datos.EMPRESA">
        </div>
        <div>
            <label for="">NIF</label>
            <input type="text" name="NIF" v-model="datos.NIF">
        </div>
        <div>
            <label for="">Dirección</label>
            <input type="text" name="DIRECCION" v-model="datos.DIRECCION">
        </div>
        <div>
            <label for="">Población</label>
            <input type="text" name="POBLACION" v-model="datos.POBLACION">
        </div>
        <div>
            <label for="">Código Postal</label>
            <input type="text" name="CODIGO_POSTAL" v-model="datos.CODIGO_POSTAL">
        </div>
        <div>
            <label for="">Teléfono</label>
            <input type="text" name="TELEFONO" v-model="datos.TELEFONO">
        </div>
        <div>
            <label for="">Web</label>
            <input type="text" name="WEB" v-model="datos.WEB">
        </div>
        <div>
            <label for="">Plataforma Web</label>
            <input type="text" name="PLATAFORMA_WEB" v-model="datos.PLATAFORMA_WEB">
        </div>
        <div>
            <label for="">Forma Pago</label>
            <input type="text" name="FORMA_PAGO" v-model="datos.FORMA_PAGO">
        </div>
        <div>
            <label for="">Plazo Pago</label>
            <input type="text" name="PLAZO_PAGO" v-model="datos.PLAZO_PAGO">
        </div>
        <div>
            <label for="">IBAN</label>
            <input type="text" name="IBAN">
        </div>
        <div>
            <label for="">Observaciones</label>
            <input type="text" name="CONDICIONES_ESPECIALES" v-model="datos.CONDICIONES_ESPECIALES">
        </div>
    </form>
</template>

<script>
import {mapState,mapMutations,mapActions} from 'vuex'
export default {
    name:'EmpresaGeneral',
    data: function (){
        return {
            tabla: 'empresas'
        }
    },
    props:{
        datos: Object
    },
    computed: {
        ...mapState(['detalles','listas','tablas','cache'])
    },
    methods: {
        ...mapActions(['api']),
        changes(event){
            let campo = null
            let valor = null
            if(event!==null && event.target!==undefined){
                campo = event.target.name
                valor = event.target.value
            }else{
                campo = object[0]
                valor = object[1]
                if(valor){
                    this.tablas[this.tabla][this.cache.actuales[this.tabla]]['Z'+campo] = this.listas[campo.toLowerCase()].filter(function (e){
                        return e['code'] === valor
                    })[0]['label']
                }else{
                    this.tablas[this.tabla][this.cache.actuales[this.tabla]]['Z'+campo] = null
                }
            }
            let id = this.$route.params.id
            this.api([
                {'campo':'field','valor':campo},
                {'campo':'value','valor':valor},
                {'campo':'ID','valor':id},
                {'campo':'detalle','valor':this.tabla},
                'guardardetalle'
            ])
        }
    }
}
</script>

<style scoped>
form{
    text-align: left;
    margin: 2%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5%;
}
form div label{
    display: block;
}
</style>